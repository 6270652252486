import React from 'react'; // useState, useEffect,
import { Box, useTheme, Button, Grid, Typography } from '@mui/material'; // Avatar, Link as ExternalLink, , Paper

export { DonateIntro };
const DonateIntro = props => {
  //const theme = useTheme();
  //const { appState, dispatch } = useAppContext();
  return (
    <Box sx={{ m: '2.0rem'}}>
    <Typography variant="h3" sx={{ textAlign: 'center', mb: '1rem'}}>
      Invest in public safety that's in your best interest.
    </Typography>

    <Typography variant="h6" sx={{ mb: '1rem'}}>
      We're counting on your support to ensure public is informed and made aware about the unsafe recalled products. Your contributions help sustain this site, promote awareness and save lives. It takes all of us.
    </Typography>

    <Typography variant="body1" sx={{ mb: '1rem'}}>
      Web site user donations assist in maintaing and enhancing this site. Your gift will be made to Kripa, Inc and specifically for improving the site content and usability. 
    </Typography>
    <Typography variant="body1" sx={{ mb: '1rem'}}>
      Already a sustainer? You can manage your monthly donation gift by logging into our partner processor site by clicking here.
    </Typography>

    <p>
      How Much:
    </p>
    <p>
      Monthly Donation
    </p>
    <p>
      One-time Donation
    </p>
    <p>
      Have a comments or suggestions? Use the Contact Us link below.
    </p>

    </Box>

  );
};
