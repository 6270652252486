import React, { useState, useEffect } from 'react';
import { Grid, useTheme, Box, Paper, Skeleton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import {loadStripe} from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'

import {
  useAppContext,
} from 'clnt-common';

import {
  DonateIntro,
} from '#src';

let isBrowser = false;
if (typeof window !== 'undefined') {
  isBrowser = true;
}

//import CheckoutForm from './CheckoutForm'

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !isBrowser) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    
    const winLocOrigin = isBrowser ? window.location.origin : 'https://localhost';
    
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: `${window.location.origin}/completion`,
        return_url: `${winLocOrigin}/donate`,
      },
    });
    
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  }

  return (
    <form style={{
      border: '#F6F9FC solid 1px',
      borderRadius: '3px',
      padding: '20px',
      margin: '40px',
      boxShadow: '0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
    }}
      onSubmit={handleSubmit}>
      <LinkAuthenticationElement id="link-authentication-element"
        // Access the email value like so:
        // onChange={(event) => {
            //  setEmail(event.value.email);
            // }}
        //
        // Prefill the email field like so:
        // options={{defaultValues: {email: 'foo@bar.com'}}}
      />
      <PaymentElement id="payment-element" />
      <button style={{
	backgroundColor: '#635BFF',
	borderRadius: '3px',
	color: 'white',
	border: 0,
	padding: '12px 16px',
	marginTop: '16px',
	fontWeight: 600,
	cursor: 'pointer',
	transition: 'all 0.2s ease',
	display: 'block',
	//&&:hover filter: 'contrast(115%)'
 
	//button:active {
	//transform: translateY(0px) scale(0.98);
	//filter: brightness(0.9);
	//}
	//button:disabled {
	//opacity: 0.5;
	//cursor: none;
	//}

     }} disabled={isLoading || !stripe || !elements} id="submit" >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

function Payment(props) {
  const { stripePromise, amount } = props;
  const [ clientSecret, setClientSecret ] = useState('');

  const { appState, dispatch } = useAppContext();
  const host = appState.getEnvVar.API_SRVR_PYMT_URL;   // "https://apipymt-kripa-com.docker.localhost"

  useEffect(() => {

    async function fetchData() {
      const intent = await fetch(`${host}/create-payment-intent?amount=${amount}`, {
	headers: {
          'Content-Type': 'application/json',
	  'Authorization': `Bearer ${appState.token}`
	},
	}).then(async (r) => r.json());
      setClientSecret(intent.clientSecret);
    }

    fetchData();
  }, []);

  return (
    <>
      <h1>Payment</h1>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

function Completion(props) {
  const [ messageBody, setMessageBody ] = useState('');
  const { stripePromise } = props;

  useEffect(() => {
    if (!stripePromise || !isBrowser) return;

    const winLoc = isBrowser ? window.location : 'https://localhost';
    stripePromise.then(async (stripe) => {
      const url = new URL(winLoc);
      const clientSecret = url.searchParams.get('payment_intent_client_secret');
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

      setMessageBody(error ? `> ${error.message}` : (
        <>&gt; Payment {paymentIntent.status}: <a style={{color: 'var(--accent-color)', fontWeight: '900' }} href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`} target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
      ));
    });

  }, [stripePromise]);
  const dispMsg = messageBody ? 'block' : 'none';
  return (
    <>
    <h1>Thank you!</h1>

    <div style={{
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, \'Courier New\'',
      display: dispMsg, // hide initially, then show once the first message arrives
      backgroundColor: '#0A253C',
      color: '#00D924',
      padding: '20px',
      margin: '20px 0',
      borderRadius: '3px',
      fontSize: '0.7em'
    }}
      role="alert">{messageBody}</div>
    </>
  );
}


// <Route path="/" element={<Payment stripePromise={stripePromise} />} />
// <Route path="/completion" element={<Completion stripePromise={stripePromise} />} />

export { DonateDtls };
const DonateDtls = props => {
  const theme = useTheme();
  let isDonateComplete = false;
  
  const [ stripePromise, setStripePromise ] = useState(null);
  const [ amount, setAmount ] = useState(10);

  const { appState, dispatch } = useAppContext();
  const host = appState.getEnvVar.API_SRVR_PYMT_URL;   // "https://apipymt-kripa-com.docker.localhost"
  
  useEffect(() => {

    async function fetchData() {
      const { publishableKey } = await fetch(`${host}/configpymt`, {
	headers: {
          'Content-Type': 'application/json',
	  'Authorization': `Bearer ${appState.token}`
	},
      }).then(async (r) => await r.json());
      console.log('DonateDtls:useEffect publishableKey : ' + publishableKey);
      setStripePromise(loadStripe(publishableKey));
    };

    fetchData();
  }, []);

  if (isBrowser) {
    const winLoc = isBrowser ? window.location : 'https://localhost';

    const url = new URL(winLoc);
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    isDonateComplete = !!clientSecret;
  }

  // Capture amount
  // Some way to capture amount value
  // Once captured and Payment component displayed, the intent is already registered at Stripe.
  // Have button which says: Donate Now/Accept the amout/etc
  // so we can go ahead and display the Payment fields
  // server converts to cents

  const amountHtml = <p>Capture amount</p>;
  
  return (
    <>
    <Box>
      <DonateIntro />
    </Box>
    <Box sx={{ px: '10rem', py: '4rem'}}>
      <Typography variant="h5" >
	Invest in public safety that's in your best interest.
      </Typography>
    </Box>

    { amountHtml && !amount ? amountHtml : null }
      

    { amount ?
    <Payment stripePromise={stripePromise} amount={amount} />
      :
      null
    }

    { isDonateComplete ?
    <Completion stripePromise={stripePromise} />
      :
      null
    }
    </>
  );
};

